'use client';

import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';
import cn from 'classnames';

import styles from './Tabs.module.scss';

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    className={cn(styles.list, className)}
    data-count={React.Children.count(props.children)}
    ref={ref}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger className={cn(styles.trigger, className)} ref={ref} {...props} />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & { preserveNativeAriaLabelledby?: boolean }
>(({ preserveNativeAriaLabelledby, ...props }, ref) => {
  // ADA: We are removing labelledby with TabsTrigger name to make screen reader read content instead.
  // ADA: role="text" make VoiceOver to read all inner content
  return (
    <TabsPrimitive.Content
      {...(!preserveNativeAriaLabelledby && { 'aria-labelledby': undefined })}
      ref={ref}
      role="text"
      {...props}
    />
  );
});
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
